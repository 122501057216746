import $ from 'jquery';
import 'bootstrap';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import AOS from 'aos';
import './js/jquery.easing.min.js';
import './js/email.js';
import VenoBox from 'venobox';

$(function () {

    if ($('#preloader').length) {
        $('#preloader').delay(50).fadeOut('fast', function () {
            $(this).remove();
        });
    }

    new VenoBox({
        selector: '.venobox',
        share: false
    });

    var portfolioIsotope = new Isotope('.portfolio-container', {
        itemSelector: '.portfolio-item'
    });

    imagesLoaded('.portfolio-container', function () {
        portfolioIsotope.layout();
    });

    var scrolltoOffset = $('#header').outerHeight() - 2;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            if (target.length) {
                e.preventDefault();

                var scrollto = target.offset().top - scrolltoOffset;
                if ($(this).attr("href") == '#header') {
                    scrollto = 0;
                }

                $('html, body').animate({
                    scrollTop: scrollto
                }, 1500, 'easeInOutExpo');

                if ($(this).parents('.nav-menu, .mobile-nav').length) {
                    $('.nav-menu .active, .mobile-nav .active').removeClass('active');
                    $(this).closest('li').addClass('active');
                }

                if ($('body').hasClass('mobile-nav-active')) {
                    $('body').removeClass('mobile-nav-active');
                    $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
                    $('.mobile-nav-overly').fadeOut();
                }
                return false;
            }
        }
    });

    function aos_init() {
        AOS.init({
            duration: 1000,
            once: true
        });
    }

    aos_init();

});
